import React, { Component } from 'react'
import { Redirect, Route, HashRouter } from 'react-router-dom'
import Login from './Login/';
import Forgot from './Forgot/'
import ForgotVerify from './Forgot/verify'
import Verify from './Verify'
import "./video.css";
import Register from "./Register";

class Authentification extends Component {
    render() {
        return (
            <HashRouter>
                <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", overflowY: "auto", overflowX: "hidden" }}>
                    <div style={{ float: "left", width: "100%", background: "#0b385b", padding: 20, height: "100%", overflow: "auto", boxShadow: "3px 0px 50px 0px rgba(0,0,0,0.75)" }}>
                        {/* <Route path={"*"} exact component={()=><Login  />} /> */}
                        <Route path={"/"} exact component={() => <Login verify={false} register={false} />} />
                        <Route path={"/Authentification"} exact component={() => <Login verify={false} register={false} />} />
                        <Route path={"/Authentification/forgot-password"} exact component={Forgot} />
                        <Route path={"/Authentification/forgot-password/Verify/:token"} exact component={ForgotVerify} />
                        <Route path={"/Authentification/Verify/:token"} component={Verify} />
                        <Route path={"/Authentification/Register"} component={Register} />
                        <Route path={"/Authentification/Register-Sucessfull"} exact component={() => <Login verify={false} register={true} />} />
                        <Route path={"/Authentification/Register-Error"} exact component={() => <Login verify={false} register={true} failed={true} />} />
                        <Route path={"/Authentification/Verify-Successfull"} exact component={() => <Login verify={true} register={false} />} />
                    </div>
                </div>
            </HashRouter>
        )
    }
}

export default Authentification