import React, { Component } from 'react'
import { render } from 'react-dom'
import { HashRouter, withRouter } from 'react-router-dom'

import client from './shared/feathers'

import 'semantic-ui-css/semantic.min.css'
import './shared/semantic/semantic.material.min.css'
import './shared/extra.css'
import Authentification from './Authentication';
import Dashboard from './Dashboard';

class PreApp extends Component {
    state = {
        login: null,
        id: "tedt"
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.get('noauth')) {
            if (params.get('noauth') == "1") {
                return
            }
        } else {
            client.authenticate().catch(() => this.setState({ login: false }));
            client.on('authenticated', () => { this.setState({ login: true }) })
            client.on('logout', () => { this.setState({ login: false }) })
        }
    }
    render() {
        if (this.state.login == null) {
            return <Authentification loading></Authentification>
        } else if (this.state.login == false) {
            return <Authentification />
        }
        return <Dashboard />

    }

}
let App = withRouter(PreApp)
render(<HashRouter><App /></HashRouter>, document.querySelector('#app'))


