import React, { Component } from "react";
import { Input, Form, Button, Header, Grid } from "semantic-ui-react";
import client from "../../shared/feathers";
import RegisterContext from "./context";

var strongRegex = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
);

class Step2 extends Component {
  state = {
    password: "",
    emailUsed: false,
    phoneUsed: false,
  };
  checkUnique() {
    client
      .service("authManagement")
      .create({
        action: "checkUnique",
        value: {
          phone: this.context.user.phone,
          email: this.context.user.email,
        },
      })
      .then(() => {
        this.setState({ emailUsed: false, phoneUsed: false });
      })
      .catch((err) => {
        this.setState({
          emailUsed: err.errors.email ? true : false,
          phoneUsed: err.errors.phone ? true : false,
        });
      });
  }
  render() {
    let { user, changeUser, step2 } = this.context;
    return (
      <Form
        onSubmit={() =>
          this.props.history.push("/Authentification/Register/Step-2")
        }
      >
        <Header>
          Benutzer erstellen
          <Header.Subheader>Bitte füllen Sie die Felder aus.</Header.Subheader>
        </Header>
        <Grid columns="equal" style={{ marginBottom: 0 }}>
          <Grid.Column width="4">
            <Form.Select
              value={user.salutation}
              name="salutation"
              onChange={(e, { name, value }) => changeUser(name, value)}
              required
              value={user.salutation}
              fluid
              options={[
                { key: "Herr", value: "Herr", text: "Herr" },
                { key: "Frau", value: "Frau", text: "Frau" },
              ]}
              label="Anrede"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={user.firstname}
              name="firstname"
              onChange={(e, { name, value }) => changeUser(name, value)}
              value={user.firstname}
              label="Vorname"
              required
              placeholder="Max"
              onChange={(e, { name, value }) => changeUser(name, value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              value={user.lastname}
              name="lastname"
              onChange={(e, { name, value }) => changeUser(name, value)}
              value={user.lastname}
              label="Nachname"
              required
              placeholder="Mustermann"
              onChange={(e, { name, value }) => changeUser(name, value)}
            />
          </Grid.Column>
        </Grid>
        <Form.Field>
          <label>
            E-Mail<sup>*</sup>
          </label>
          <Input
            error={this.state.emailUsed}
            value={user.email || ""}
            name="email"
            onChange={(e, { name, value }) => {
              changeUser(name, value);
              this.checkUnique();
            }}
            type="email"
            required
            placeholder="max.mustermann@layagroup.de"
          />
          {this.state.emailUsed && (
            <small style={{ marginTop: "-1em" }} className="helper">
              Diese E-Mail ist bereits in Benutzung
            </small>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Telefonnummer<sup>*</sup>
          </label>
          <Input
            error={this.state.emailUsed}
            value={user.phone}
            name="phone"
            onChange={(e, { name, value }) => {
              changeUser(name, value);
              this.checkUnique();
            }}
            placeholder="+49 17111123124"
          />
          {this.state.phoneUsed && (
            <small style={{ marginTop: "-1em" }} className="helper">
              Diese Telefonnummer ist bereits in Benutzung
            </small>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Passwort <sup>*</sup>
          </label>
          <Input
            name="password"
            value={user.password}
            type="password"
            required
            onChange={(e, { name, value }) => changeUser(name, value)}
          />
          {!strongRegex.test(user.password) && (
            <small style={{ marginTop: "-1em" }} className="helper">
              Mindestens 8 Zeichen, einen Groß- und einen Kleinbuchstaben, sowie
              eine Nummer.
            </small>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Passwort bestätigen <sup>*</sup>
          </label>
          <Input
            value={this.state.password}
            type="password"
            required
            onChange={(e, { name, value }) =>
              this.setState({ password: value })
            }
          />
          {this.state.password != user.password && (
            <small style={{ marginTop: "-1em" }} className="helper">
              Bitte überprüfen Sie, dass beide Passwörter gleich sind.
            </small>
          )}
        </Form.Field>
        <div>
          <Button
            as="a"
            onClick={() => {
              this.props.history.push("/Authentification/Register");
            }}
          >
            Zurück
          </Button>
          <Button
            onClick={() =>
              this.props.history.push("/Authentification/Register/Step-3")
            }
            primary
            disabled={
              this.state.phoneUsed ||
              this.state.emailUsed ||
              this.state.password != user.password ||
              user.password == "" ||
              !strongRegex.test(user.password)
            }
          >
            Weiter
          </Button>
        </div>
      </Form>
    );
  }
}

Step2.contextType = RegisterContext;

export default Step2;
