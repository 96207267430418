import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Icon,
  Label,
  Button,
  Table,
  Popup,
} from "semantic-ui-react";
import client from "../../shared/feathers";
import Currency from "react-currency-formatter";
import moment from "moment";
import config from "../../config";

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}
class ReceiptSingle extends Component {
  state = {
    loading: true,
    error: false,
    notFound: false,
    company: null,
    sending: false,
  };
  componentDidMount() {
    this.reactive = client
      .service("receipts")
      .watch()
      .get(this.props.match.params.id)
      .subscribe(this.update.bind(this), () =>
        this.setState({ loading: false, notFound: true })
      );
  }
  async update(data) {
    data["created_at"] = moment(data["created_at"]).format("DD-MM-YYYY");
    data["until"] = moment(data["created_at"]).format("DD-MM-YYYY");
    data["products"] = JSON.parse(data["products"]);
    data["customer"] = JSON.parse(data["customer"]);
    this.setState({ ...data, loading: false });
  }

  // '254a6030243dc3c79067f961a267666bb4ef3048': data.supplier_id,
  // bcc7407444f7737569208a4c91d79a6aa7c8cb02: data.supplier_name
  async sendPipedrive() {
    this.setState({ saving: true });
    const savedDeal = await client
      .service("deals")
      .update(this.state.deal_id, {
        stockdeposit: this.state.stockdeposit,
        supplier_name: this.state.supplier_name,
        supplier_id: this.state.supplier_id,
        receiptnr: "#" + pad(this.state.id, 4),
        taxfree: this.state.taxfree,
        products: this.state.products,
        document: this.state.document,
      });
    await client.service("receipts").patch(this.state.id, { status: "send", products: JSON.stringify(savedDeal.products) });
    this.setState({ saving: false, products: savedDeal.products });
    window.location = `${config.pipedriveUrl}/deal/${this.state.deal_id}`;
  }
  componentWillUnmount() {
    if (this.reactive) {
      this.reactive.unsubscribe();
    }
  }

  calulatePrices(prdkts) {
    return prdkts.reduce(
      (acc, el) =>
        (acc ? acc : 0) +
        (el.unit2 == "TKP"
          ? (el.amount / 1000) * (el.single_price * (1 + el.surcharge / 100))
          : el.amount * (el.single_price * (1 + el.surcharge / 100))) *
        (1 - el.discount / 100),
      0
    );
  }
  render() {
    if (this.state.loading) {
      return (
        <Segment basic>
          <Segment loading placeholder></Segment>
        </Segment>
      );
    }
    if (this.state.notFound) {
      return (
        <Segment basic>
          <Segment placeholder color="red">
            {" "}
            <Header icon>
              <Icon name="search" /> Kunde wurde nicht gefunden.
            </Header>{" "}
          </Segment>
        </Segment>
      );
    }
    return (
      <Segment basic className="extrasmall">
        <Segment.Group style={{ backgroundColor: "#fff" }}>
          <Grid attached="top" columns="equal">
            <Grid.Column>
              <Segment style={{ boxShadow: "none", border: 0 }}>
                <Header>
                  Angebot #{pad(this.state.id, 4)}
                  <Header.Subheader>
                    {this.state.deal_name} {this.state.created_at}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{ textAlign: "center", paddingTop: 30 }}>
              {this.state.status == "open" && <Label color="red">Offen</Label>}
              {this.state.status == "send" && (
                <Label color="green">Gesendet</Label>
              )}
              {this.state.status == "canceld" && (
                <Label color="red" basic>
                  Gelöscht
                </Label>
              )}
            </Grid.Column>
            <Grid.Column
              style={{ textAlign: "right", paddingTop: 29, paddingRight: 30 }}
            >
              <Button.Group style={{ float: "right", marginRight: 10 }}>
                {/*<Button icon labelPosition='right' positive><Icon name="add" /> Zahlung</Button>*/}
                {
                  <Button
                    onClick={() =>
                      this.props.history.push("/receipts/add/" + this.state.id)
                    }
                    icon
                    labelPosition="right"
                  >
                    <Icon name="edit" /> Bearbeiten
                  </Button>
                }
                {/* {!!(this.state.document) && <Button as="a" href={"http://localhost:3035/uploads/"+this.state.document} target="_blank" icon labelPosition='right'><Icon name="file pdf" /> Angebot</Button>} */}
                {!!this.state.document && (
                  <Button
                    as="a"
                    href={
                      `${config.backendUrl}/uploads/${this.state.document}`
                    }
                    target="_blank"
                    icon
                    labelPosition="right"
                  >
                    <Icon name="file pdf" /> Angebot
                  </Button>
                )}
                {/*this.state.status=="payed" && <Button icon labelPosition='right'><Icon name="file pdf" /> Quittung</Button>*/}
                {this.state.status == "open" && (
                  <Button
                    onClick={() =>
                      client
                        .service("receipts")
                        .patch(this.state.id, { status: "canceld" })
                    }
                    labelPosition="right"
                    icon
                    negative
                  >
                    <Icon name="trash" /> Löschen
                  </Button>
                )}
                {
                  <Button
                    loading={this.state.saving}
                    onClick={() => this.sendPipedrive()}
                    floating
                    labeled
                    button
                    positive
                    className="icon positive"
                  >
                    Zu Pipedrive senden
                  </Button>
                }
              </Button.Group>
            </Grid.Column>
          </Grid>
          <Segment.Group attached horizontal>
            <Segment>
              <div>
                <Header>Kunde</Header>
                <div>
                  <b>{this.state.customer.name}</b>{" "}
                  {this.state.customer.address}
                </div>
              </div>
            </Segment>
            <Segment>
              <div>
                <Header>Ansprechpartner:</Header>
                <b>{this.state.contact_name}</b>
              </div>
            </Segment>
          </Segment.Group>
          {this.state.timerange && (
            <Segment.Group attached horizontal>
              <Segment>
                <b>Kampagnenzeitraum:</b> {this.state.timerange}
              </Segment>
            </Segment.Group>
          )}
          <Table attached>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Kanal <br />
                  <small>/ VL / Filiale</small>
                </Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Produkt <br />
                  <small>/ Format</small>
                </Table.HeaderCell>
                <Table.HeaderCell>Beschreibung</Table.HeaderCell>
                <Table.HeaderCell collapsing>Menge</Table.HeaderCell>
                <Table.HeaderCell collapsing>Einheit</Table.HeaderCell>
                <Table.HeaderCell collapsing>Einzelpreis</Table.HeaderCell>
                <Table.HeaderCell collapsing>Einheit</Table.HeaderCell>
                <Table.HeaderCell collapsing>Aufschlag</Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Einzelpreis
                  <br />
                  <small>nach Aufschlag</small>
                </Table.HeaderCell>
                <Table.HeaderCell collapsing>Gesamtpreis</Table.HeaderCell>
                <Table.HeaderCell collapsing>Rabatt</Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Gesamtpreis
                  <br />
                  <small>nach Rabatt</small>
                </Table.HeaderCell>
                <Table.HeaderCell collapsing></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.products.map((el, index) => (
                <Table.Row>
                  <Table.Cell verticalAlign="top">{el.sku}</Table.Cell>
                  <Table.Cell verticalAlign="top">
                    {el.channel}
                    <br />
                    {el.salesline}
                    <br />
                    {el.department}
                  </Table.Cell>
                  <Table.Cell verticalAlign="top">
                    {el.product_name} {el.format}
                  </Table.Cell>
                  <Table.Cell>{el.description}</Table.Cell>
                  <Table.Cell>{el.amount}</Table.Cell>
                  <Table.Cell>{el.unit}</Table.Cell>
                  <Table.Cell>{el.single_price}</Table.Cell>
                  <Table.Cell>{el.unit2}</Table.Cell>
                  <Table.Cell>{el.surcharge}</Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={el.single_price * (1 + el.surcharge / 100)}
                      currency="EUR"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={
                        el.unit2 == "TKP"
                          ? (el.amount / 1000) *
                          (el.single_price * (1 + el.surcharge / 100))
                          : el.amount *
                          (el.single_price * (1 + el.surcharge / 100))
                      }
                      currency="EUR"
                    />
                  </Table.Cell>
                  <Table.Cell>{el.discount}</Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={
                        (el.unit2 == "TKP"
                          ? (el.amount / 1000) *
                          (el.single_price * (1 + el.surcharge / 100))
                          : el.amount *
                          (el.single_price * (1 + el.surcharge / 100))) *
                        (1 - el.discount / 100)
                      }
                      currency="EUR"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {!!el.intern && (
                      <Popup
                        content={el.intern}
                        trigger={<Button icon="info" />}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Segment.Group horizontal attached="bottom">
            <Segment style={{ maxWidth: "50%" }}></Segment>
            <Segment>
              {!this.state.taxfree && (
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="right">Gesamt Netto</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Currency
                          quantity={Number(
                            this.calulatePrices(this.state.products)
                          )}
                          currency="EUR"
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">zzgl. MWST 19%</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Currency
                          quantity={
                            this.calulatePrices(this.state.products) * 0.19
                          }
                          currency="EUR"
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        Gesamt inkl. MWST
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Currency
                          quantity={
                            this.calulatePrices(this.state.products) * 1.19
                          }
                          currency="EUR"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
              {!!this.state.taxfree && (
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="right">Gesamt</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Currency
                          quantity={Number(
                            this.calulatePrices(this.state.products)
                          )}
                          currency="EUR"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Segment>
    );
  }
}

export default ReceiptSingle;
