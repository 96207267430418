import queryString from "query-string";

function parseQueryParams() {
  // parses query params regardless if it uses hash routing or not and where the query string is located (before hash or after)
  // becase hash router does not parse anything what is before the hash, we have to use window.location
  const url = window.location.href;
  const queryStringIndex = url.indexOf('?');
  const hashIndex = url.indexOf('#');
  let query = '';

  if (queryStringIndex !== -1) {
    if (hashIndex === -1 || queryStringIndex < hashIndex) {
      // Query string is before the hash
      query = url.substring(queryStringIndex, hashIndex !== -1 ? hashIndex : undefined);
    } else {
      // Query string is after the hash
      query = url.substring(hashIndex).split('?')[1] || '';
    }
  }
  return queryString.parse(query);
}

export default parseQueryParams;